import React, { useState, useEffect, useCallback } from 'react'
import { Col, Table, Row } from 'react-bootstrap'
import Steps from 'rc-steps';
import { Status } from '@rd-web-markets/shared/dist/util/Status';
import 'rc-steps/assets/index.css';
import { useTranslation } from 'react-i18next';
import { makeKey } from '@rd-web-markets/shared/dist/hooks/useConvertForTranslationKey';

const icons = {
  finish: <span className="material-icons">done</span>,
  error: <span className="material-icons">close</span>,
};

const ClaimState = ({ tasks, markTaskComplete, claimGroup }) => {
  const [currentClaimTaskIndex, setCurrentClaimTaskIndex] = useState(0);
  const [currentUserTaskIndex, setCurrentUserTaskIndex] = useState(0);
  const [stepsItems, setStepsItems] = useState([]);
  const { t } = useTranslation();

  const getCurrentTaskIndex = useCallback(() => {
    const index = tasks.findIndex((task => task.status === 'in_progress'));
    if (index === -1) {
      const firstNotStartedIndex = tasks.findIndex((task => task.status === 'not_started'));
      return firstNotStartedIndex === -1 ? tasks.length : firstNotStartedIndex;
    } else {
      return index;
    }
  }, [tasks]);

  useEffect(() => {
    const currentClaimTaskIndex = getCurrentTaskIndex();
    setCurrentClaimTaskIndex(currentClaimTaskIndex);
    currentClaimTaskIndex === tasks.length 
      ? setCurrentUserTaskIndex(currentClaimTaskIndex-1) 
      : setCurrentUserTaskIndex(currentClaimTaskIndex);
  }, [tasks, currentClaimTaskIndex, getCurrentTaskIndex]);

  useEffect(() => {
    const stepsItems = tasks.map((task, index) => {
      return {
        // using description to highlight task selected by the user
        title: currentUserTaskIndex === index ? '' : t(makeKey(task.category_title)) ,
        description: currentUserTaskIndex === index ? t(makeKey(task.category_title)) : '',
        status: index < currentClaimTaskIndex ? 'finish' : index === currentClaimTaskIndex ? 'process' : 'wait',
      }
    });
    setStepsItems(stepsItems);
  }, [tasks, currentClaimTaskIndex, currentUserTaskIndex]);

  return (
    <Row>
      <Col md={2} className='border-right border-light'>
        <Steps
          current={currentUserTaskIndex}
          direction="vertical"
          items={stepsItems}
          icons={icons}
          onChange={stepIndex => setCurrentUserTaskIndex(stepIndex)}
        />
      </Col>
      <Col md={10}>
        {currentUserTaskIndex > currentClaimTaskIndex && <div className="alert alert-warning" role="alert">{t('you_have_unfinished_previous_tasks')}</div>}
        <Table>
          <thead>
            <tr>
              <th>{t('task_name')}</th>
              <th>{t('status')}</th>
              {markTaskComplete && <th>{t('actions')}</th>}
            </tr>
          </thead>
          <tbody>
            {
              tasks[currentUserTaskIndex] && tasks[currentUserTaskIndex].tasks.map((task, taskIndex) => (
                <tr key={taskIndex}>
                  <td>{t(makeKey(task.title))}</td>
                  <td>{task.completed ? <Status status='success' text={t('validated')} /> : <Status status={t('warning')} text={t('pending')} />}</td>
                  <td>
                    {markTaskComplete && <div className="cursor-pointer mt-2 mb-2"
                      key={taskIndex}
                      onClick={() => markTaskComplete(tasks[currentUserTaskIndex], currentUserTaskIndex, taskIndex, claimGroup)}>
                      <span className="mx-1" >
                        {task.completed ? <span className="text-primary"><span className="material-icons">close</span> {t('cancel_task')}</span> : <span className="text-primary"><span className="material-icons">done</span> {t('validate_task')}</span>}
                      </span>
                    </div>}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export default ClaimState
