import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeHighlighter, FormattedNumberInput, Table } from '@rd-web-markets/shared/dist/util';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Filters from '@rd-web-markets/shared/dist/util/Filters';
import { AddButton, SaveButton, Button } from '@rd-web-markets/shared/dist/util/buttons';
import ImmutableUnderReviewFormControl from '@rd-web-markets/shared/dist/util/reviewEditControls/ImmutableUnderReviewFormControl';
import ProjectReportDateColumns from '@rd-web-markets/shared/dist/util/date/ProjectReportDateColumns';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import { PROJECT_REPORT_DATE_PICKER_TYPE } from '@rd-web-markets/market/dist/constants';
import { TEMPLATE_SELECT_MODEL_NAME } from '@rd-web-markets/market/dist/constants';
import { PROJECT_FIELD_MODEL_NAME } from '@rd-web-markets/market/dist/constants';
import { SHOW_PROJECT_TEMPLATE_SELECT } from '@rd-web-markets/market/dist/constants';
import TemplateCateogiresForCreatingProject from '@rd-web-markets/shared/dist/util/helpers/template_categories_for_createing_project';
import { IS_PROJECT_DATE_SHORT_FORMAT } from '@rd-web-markets/market/dist/constants';
import { PROJECT_OVERVIEW_TABLE } from '@rd-web-markets/market/dist/constants';
import SetUpQuestionnaireModal from './SetUpQuestionnaireModal';
import CostFormatter from '@rd-web-markets/market/dist/claim_project_reports/claim_project_report_list/CostFormatter';

const ClaimProjectReportsList = ({reports, claimGroup, setClaimGroup, handleToaster, params, setParams, getData}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [claimProjectReports, setClaimProjectReports] = useState(reports);
  const [newReport, setNewReport] = useState({});
  const updateClaimGroupFunction = claimGroupService.useUpdateClaimGroup(claimGroup.id, setClaimGroup);
  const datePickerType = PROJECT_REPORT_DATE_PICKER_TYPE
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const defaultProjectAttributes = {
      project_name: '',
      start_date: IS_PROJECT_DATE_SHORT_FORMAT ? claimGroup?.from_date?.slice(3) : claimGroup.from_date,
      end_date: IS_PROJECT_DATE_SHORT_FORMAT ? claimGroup?.to_date?.slice(3) : claimGroup.to_date,
    };
    setNewReport(defaultProjectAttributes);
    setClaimProjectReports(claimGroup.claim_project_reports);
  }, [showProjectForm, claimGroup.claim_project_reports, claimGroup.from_date, claimGroup.to_date]);

  useEffect(() => {
    setClaimProjectReports(reports);
  }, [reports]);

  const getOrderParam = (paramName) => {
    return params.order_by?.split(' ')[0] === paramName && {order: params.order_by.split(' ')[1] };
  };

  const createHeaders = () => {
    return PROJECT_OVERVIEW_TABLE.headings.map(h => {
      if (h === 'name') {
        return { text: t('name'), sortable: true, orderBy: 'project_name', ...(getOrderParam('project_name')) }
      } else {
        return { text: t(h)}
      }
    })
  }

  const headers = createHeaders();

  const businessUnits = report => {
    return report.project_business_units.map((pbu, index) => {
      const businessUnitName = pbu.business_unit.name
      const isLongName = businessUnitName.length > 50
      return (
        <OverlayTrigger placement='top' overlay={isLongName ? <Tooltip>{businessUnitName}</Tooltip> : <div/>}>
          <Badge key={index} variant="primary" className='mr-2 mb-1'>
            {isLongName ? businessUnitName.substring(0, 50) + '...' : businessUnitName}
          </Badge>
        </OverlayTrigger>
      );
    });
  }

  const QuestionnairesButton = ({ report }) => {
    return (
      <Button onClick={() => history.push(`/${user.account_type}/claim_groups/${report.claim_group_id}/technical_proof/project_reports/${report.id}/questionnaires`)}>
        {t('questionnaires')}
      </Button>
    );
  }

  const mappingCompletionStatuses = {
    'unstarted': 'not_started',
    'begun_tech_interview_scheduled': 'in_progress_tech_interview_scheduled',
    'begun_tech_interview_completed': 'in_progress_tech_interview_completed',
    'begun_in_manager_qa': 'in_progress_in_manager_qa',
    'begun_manager_qa_completed': 'in_progress_manager_qa_completed',
    'finished': 'completed',
  }
  const rows = claimProjectReports?.map(report => {
    const columns = PROJECT_OVERVIEW_TABLE.columns.map(c => {
      switch(c) {
        case 'project_name':
          return <Link to={`/${user.account_type}/claim_groups/${report.claim_group_id}/technical_proof/project_reports/${report.id}`}>{report.project_name}</Link>
        case 'assessment_status':
          return t(report.assessment)
        case 'category':
          return t(report.category)
        case 'bussiness_units':
          return businessUnits(report)
        case 'total_cost':
          return (<CostFormatter 
                    disabled={true} 
                    object={report} 
                    keyName={'total_cost'}
                  />)
        case 'total_qe':
            return (<CostFormatter 
                      disabled={true} 
                      object={report} 
                      keyName={'total_qe'}
                    />)
        case 'cost':
          return report.total_cost ? t('completed') : t('not_started')
        case 'completion_status':
          return t(mappingCompletionStatuses[report.completion_status])
        case 'documents':
          return (
            <Link
              className='text-center'
              to={`/${user.account_type}/claim_groups/${report.claim_group_id}/technical_proof/project_reports/${report.id}?key=documents&model_type=ClaimProjectReport&model_id=${report.id}&folder=`}>
              <span className="material-icons text-center">upload_file</span>
            </Link>
          )
        case '':
          return <QuestionnairesButton report={report} />
        default:
          return console.log('Heading doesnt exist')
      }
    });
    return {
      key: report.id,
      columns: columns
    }
  });

  const addProject = () => {
    setShowProjectForm(true);
  }; 

  const orderParams = (eventKey, direction) => {
    return `${headers[eventKey].orderBy} ${direction}`
  };

  const sortreports = (eventKey, direction) => {
    setParams({...params, order_by: orderParams(eventKey, direction) });
  };

  const changeReport = (e) => {
    setNewReport({
      ...newReport,
      [e.target.name]: e.target.value
    });
  };

  const setProjectTemplateForNewProject = (report, userSelectedProjectReportTemplate) => {
    // for all categories from the template - remove the id, so we create new ones for the new project
    userSelectedProjectReportTemplate.report_template_categories.forEach((category) => delete category.id);

    // Some fields are in the template, some fields are not - so here we add the missing text fields - Ro1, Ro2, etc.
    const reportTemplateWithAllCategories = TemplateCateogiresForCreatingProject(userSelectedProjectReportTemplate)

    reportTemplateWithAllCategories.report_template_categories.forEach((c) => {
      c.content = c.content || 'default_text';
    });

    setNewReport({
      ...report,
      template_name: userSelectedProjectReportTemplate.name,
      [PROJECT_FIELD_MODEL_NAME + '_attributes']: reportTemplateWithAllCategories?.report_template_categories,
    });

    claimGroup.selectedTemplate = claimGroup.report_template_project_specific?.name ? {} : reportTemplateWithAllCategories;
    setClaimGroup({ ...claimGroup });
  }

  const selectTechnicalTemplate = (e, report) => {
    const userSelectedProjectReportTemplate = claimGroup[TEMPLATE_SELECT_MODEL_NAME].find((t) => t.id === Number(e.target.value));
    if(userSelectedProjectReportTemplate) {
      setProjectTemplateForNewProject(report, userSelectedProjectReportTemplate);
    }else{
      setNewReport({
        ...newReport,
        template_name: ''
      })
    }
  };

  const createReport = async() => {
    await updateClaimGroupFunction({ ...claimGroup, claim_project_reports_attributes: [newReport] });
    await getData();
    setShowProjectForm(false);
  };

  return (
    <div>
      <SetUpQuestionnaireModal
        show={modalShow}
        title={t('questionnaire_set_up')}
        onHide={() => setModalShow(false)}
        submitText={t('send')}
        user={user}
        claimProjectReports={claimProjectReports}
        claimGroup={claimGroup}
      />
      <Row>
        <Col className='text-right'>
          <Button onClick={() => setModalShow(true)} className='mr-2'>
            {t('send_questionnaire')}
          </Button>
          <AddButton
            variant="info"
            isImmutableUnderReview={true}
            disabled={false}
            onClick={() => addProject()}
            text={t('add_project')}
          />
          <Filters
            params={params}
            setParams={setParams}
            filters={[
              { name: 'name', label: t('name'), type: 'text' },
              { name: 'project_category', label: t('project_category'), type: 'select', options: ['rnd', 'non_rnd', 'tbc'] },
              { name: 'business_unit', label: t('business_unit'), type: 'text' },
              { name: 'assessment_status', label: t('assessment_status'), type: 'select', options: ['not_started', 'in_progress', 'completed'] },
              { name: 'project_status', label: t('project_status'), type: 'select', options: ['unstarted', 'begun', 'finished'] },
            ]}
          />
        </Col>
      </Row>

      <Row style={{display: showProjectForm ? 'block' : 'none'}} >
        <Col>
          <div className='table-responsive-responsive'>
            <table className='table table-hover'>
              <thead>
                <tr className='text-primary app-fs-table-header'>
                  <th>{t('project_name')}</th>
                  <th>{t('rnd_project_start_date')}</th>
                  <th>{t('rnd_project_end_date')}</th>
                  { SHOW_PROJECT_TEMPLATE_SELECT &&  
                    <>
                      <th>{t('template_name')}</th>
                      <th>{t('technical_analysis_report')}</th>
                    </>
                  }
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ImmutableUnderReviewFormControl
                      disabled={false}
                      name='project_name'
                      value={newReport.project_name}
                      onChange={changeReport}
                      />
                  </td>
                  <ProjectReportDateColumns
                    datePickerType={datePickerType}
                    disabled={false}
                    report={newReport}
                    handleProjectReportChange={changeReport}
                    handleToaster={handleToaster}
                  />
                  { SHOW_PROJECT_TEMPLATE_SELECT &&
                    <>
                      <td>
                        <ImmutableUnderReviewFormControl as='select' name='selectedTemplate' onChange={(e) => selectTechnicalTemplate(e, newReport)} size='md'>
                          <option value='0'></option>
                          {claimGroup[TEMPLATE_SELECT_MODEL_NAME]?.map((template) => {
                            return (
                              <option key={template.id} value={template.id}>
                                {template.name}
                              </option>
                            );
                          })}
                        </ImmutableUnderReviewFormControl>
                      </td>
                      <td>
                        {newReport.template_name}
                      </td>
                    </>
                  }
                  <td>
                    <SaveButton onClick={createReport} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      
      <Table
        headers={headers}
        rows={rows}
        onSort={(i, d) => sortreports(i, d)}
        />
    </div>
  );
}

export default ClaimProjectReportsList