import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import Navbar from '@rd-web-markets/market/dist/layout/Navbar/Navbar';
import Toast from '@components/util/Toast';
import * as QueryString from 'query-string';
import { useTranslation } from 'react-i18next'
import ErrorHandler from '@components/util/ErrorHandler';
import UnexpectedSynchronousErrorBoundary from './UnexpectedSynchronousErrorBoundary';
import { translationsService } from '@services/translations.service';
import { APP_FOOTER_TEXT, APP_RELEASE_VERSION, LOCAL_LANGUAGES } from '@rd-web-markets/market/dist/constants';
import { Loading } from '@rd-web-markets/shared/dist/util';

export const ClientRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.auth.user);
  const [show, setShow] = useState(false);
  const [toastVariant, setToastVariant] = useState('success');
  const [message, setMessage] = useState(false);
  const [translations, setTranslations] = useState(null);
  const history = useHistory();
  const { t, i18n } = useTranslation()
  const defaultLanguage = LOCAL_LANGUAGES[0]

  /**
   *
   * @param {*} m
   * @param {*} variant 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
   */
  const handleToaster = (m, variant) => {
    variant ||= 'success';
    setShow(true);
    setMessage(m);
    setToastVariant(variant);
  }

  useEffect(() => {
    const fetchTranslations = async () => {
      if(user && user.account_type === 'customer') {
        const keyValues = await translationsService.all(QueryString.stringify({locale: user?.local_language || defaultLanguage}));
        const englishValues = user?.local_language !== 'en' ? await translationsService.all(QueryString.stringify({locale: 'en' })) : keyValues;
        const json = {};
        const englishJson = {};
        englishValues.forEach(function(englishValue) {
          englishJson[englishValue.key] = englishValue.value;
        });
        keyValues.forEach(function(keyValue) {
          json[keyValue.key] = keyValue.value;
        });
        i18n.addResourceBundle('en', 'translation', englishJson, true, true);
        i18n.addResourceBundle(user?.local_language || defaultLanguage, 'translation', json, true, true);
        i18n.changeLanguage(user?.local_language || defaultLanguage);
        setTranslations(keyValues);
      }
    }
    fetchTranslations();
  }, []);

  return (
    <Route {...rest} render={(props) => {
      if (user && user.account_type === 'customer') {
        if(!translations) return <Loading />;
        return (
          <>
            <ErrorHandler>
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  right: 0,
                  zIndex: 9999,
                  width: 'max-content'
                }}
              >
                <Toast show={show} message={message} variant={toastVariant} onClose={() => setShow(false)}/>
              </div>
              <Navbar userRole="customer" basename={rest.basename} {...props} />
              <div className="wrapper" id="content-wrapper">
                <Container fluid>
                  <UnexpectedSynchronousErrorBoundary>
                    <Component {...props} handleToaster={handleToaster} accountType={user.account_type}/>
                    <div className="text-muted text-center" dangerouslySetInnerHTML={{ __html: `&copy; ${APP_FOOTER_TEXT}` }} >
                    </div>
                    <div className="text-muted text-center">
                      {APP_RELEASE_VERSION}
                    </div>
                  </UnexpectedSynchronousErrorBoundary>
                </Container>
              </div>
            </ErrorHandler>
          </>
        );
      }
      else {
        history.replace('/login');
      }
    }} />
  )
}
