import React from 'react'
import { useTranslation } from 'react-i18next';
import { makeKey } from '@rd-web-markets/shared/dist/hooks/useConvertForTranslationKey';

const ClaimTemplateList = ({claimTemplateTasks}) => {
  const { t } = useTranslation();

  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>{t('category_order')}</th>
          <th>{t('category_title')}</th>
          <th>{t('task_order')}</th>
          <th>{t('task_title')}</th>
          <th>{t('template_name')}</th>
        </tr>
      </thead>
      <tbody>
        {claimTemplateTasks && claimTemplateTasks.map((task) => {
          return (
            <tr key={task.id}>
              <td>{task.claim_template_category.order}</td>
              <td>{t(makeKey(task.claim_template_category.title))}</td>
              <td>{task.order}</td>
              <td>{t(makeKey(task.title))}</td>
              <td>{task.claim_template_category.claim_template_name}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default ClaimTemplateList
