import React from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import { Table } from '@rd-web-markets/shared/dist/util';
import companyGroupsCompaniesService from '@rd-web-markets/shared/dist/services/company_groups/companies.service';
import { companyGroupService } from '@services/company_group.service';

const CompaniesList = ({companyGroup, setCompanyGroup}) => {
  const headers = [
    {
      text: 'Companies assigned to Group'
    },
    {
      text: 'Remove from Group'
    }
  ];

  const handleDelete = async (id) => {
    await companyGroupsCompaniesService.delete(companyGroup.id, id);
    const response = await companyGroupService.get(companyGroup.id);
    setCompanyGroup(response);
  }

  const rows = () => {
    return companyGroup.companies
    .map((im) => ({
      columns: [
        im.name,
        <>
          <Button
            variant="light"
            className="p-1 d-inline-flex ml-1"
            onClick={() => handleDelete(im.id)}
          >
            <span className="material-icons md-18 text-warning">delete</span>
          </Button>
        </>,
      ]
    }));
  }

  return (
    <>
      {companyGroup.companies.length > 0 &&
        <Row>
          <Col md={12} className='text-center'>
            <Table headers={headers} rows={rows()} showControls={false} />
          </Col>
        </Row>
      }
    </>
  )
}

export default CompaniesList