import React, { useState } from 'react';
import ClaimScheduleEngagementTeam from '@rd-web-markets/market/dist/claim_group/claim_schedule/ClaimScheduleEngagementTeam';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from 'react-bootstrap';
import AllContactsTab from '@rd-web-markets/shared/dist/claim_group/claim_schedule/AllContactsTab';

const ClaimGroupEngagementTeam = ({
  claimGroup,
  company,
  handleAymingContactChange,
  setClaimGroup,
  customTitle
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(process.env.PUBLIC_URL === '/it' ? t(customTitle) : t('contacts'));

  return (
    <>
      <h2 className="text-primary mb-4">{t(title)}</h2>
      <Tabs defaultActiveKey="engagement_team" className="mb-3">
        <Tab eventKey="engagement_team" title={`${t('engagement_team')}`}>
          <ClaimScheduleEngagementTeam
            claimGroup={claimGroup}
            setClaimGroup={setClaimGroup}
            company={company}
            claimSchedule={claimGroup.claim_schedule}
            handleAymingContactChange={handleAymingContactChange}
          />
        </Tab>
        <Tab eventKey="all_contacts" title={`${t('all_contacts')}`}>
          <AllContactsTab claimGroup={claimGroup} setClaimGroup={setClaimGroup} company={company} />
        </Tab>
      </Tabs>
    </>
  )
};

export default ClaimGroupEngagementTeam
