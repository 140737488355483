import ReportTemplateForm from '@components/admin/reportTemplates/ReportTemplateForm'
import { Loading } from '@rd-web-markets/shared/dist/util';
import reportTemplateService from '@services/report_template.service';
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import reportTemplateCategoryService from '@services/report_templates/report_template_category.service';

const EditReportTemplatePage = ({handleToaster}) => {
	const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const { search } = useLocation() //Required for Italy

  useEffect(() => {
    const fetchData = async () => {
      try {
				const template = await reportTemplateService.get(id);
				setTemplate(template);
			} catch(error) {
				handleError(error);
			}
    };
    fetchData();
  }, [id, dispatch]);

  const handleChange = (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setTemplate({
      ...template,
      [event.target.name]: value,
    });
  };

  const handleCategoryChange = (event, index) => {
    const templateCategoriesCopy = template.report_template_categories;
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    templateCategoriesCopy[index][event.target.name] = value;
    setTemplate({
      ...template,
      report_template_categories: templateCategoriesCopy
    });
  };

  const handleTinyMce = (content, index) => {
    const categoriesCopy = template.report_template_categories;
    categoriesCopy[index]['content'] = content;
    setTemplate({
      ...template,
      report_template_categories: categoriesCopy,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      template.report_template_categories_attributes = template.report_template_categories;
      await reportTemplateService.update(id, template);
      handleToaster('Template updated');
      setLoading(false);
      history.replace('/admin/report_templates/' + search);
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    history.replace('/admin/report_templates/' + search);
  }

  const handleFinancialAnalysisMethodologyChange = (staffing_cost_methodology, consumables_methodology) => {
    setTemplate({
      ...template,
      staffing_cost_methodology,
      consumables_methodology
    });
  };

  const handleChangeApplyAymingStyles = async (e, index) => {
    setLoading(true);
    try {
      const reportTemplateCategory = template.report_template_categories[index];
      await reportTemplateCategoryService.update(
        template.id,
        {...reportTemplateCategory, should_apply_styles: e.target.checked}
      );
      const updatedTemplate = await reportTemplateService.get(id);
      setTemplate(updatedTemplate);
    } catch(err) {
      dispatch(handleError(err))
    } finally {
      setLoading(false);
    }
  };

  if(!template) return <Loading />

  return (
    <>
      <ReportTemplateForm
        handleChangeApplyAymingStyles={handleChangeApplyAymingStyles}
        selectedTemplate={template}
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        loading={loading}
        handleChange={handleChange}
        handleTinyMce={handleTinyMce}
        handleCategoryChange={handleCategoryChange}
        handleFinancialAnalysisMethodologyChange={handleFinancialAnalysisMethodologyChange}
      />
    </>
  )
}

export default EditReportTemplatePage
