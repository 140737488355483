import { CompanyService } from '@services/company.service';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Form, Row, Card, Breadcrumb } from 'react-bootstrap';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { UserService } from '@rd-web-markets/shared/dist/services/user.service';
import UserForm from '@components/users/UserForm';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';

const EditUserPage = ({handleToaster}) => {
  const [user, setUser] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      const result = await CompanyService.all();
      const user = await UserService.get(id);
      setCompanies(result.companies);
      setUser(user);
    }
    getData();
  }, [id]);

  const handleChange = (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setUser({
      ...user,
      [event.target.name]: value,
    });
  };

  const handleCompanyUserDelete = async index => {
    if(user.company_users[index].id) {
      const id = user.company_users[index].id
      try {
        setLoading(true);
        const companyUsers = user.company_users;
        const newCompanyUsers = companyUsers.filter(companyUser => companyUser.id !== id);
        setUser({...user, company_users: newCompanyUsers});
        setLoading(false);
      } catch(error) {
        dispatch(handleError(error))
      }
    }
    else {
      const newCompanyUsers = user.company_users;
      newCompanyUsers.splice(index, 1);
      setUser({...user, company_users: newCompanyUsers});
    }
  }

  const handleCompanyUserCreate = async (company) => {
    if(!user.company_users.find(company_user => company_user.company.name === company.name)){
      const companyUsers = user.company_users;
      const companyUsersAttributes = user.company_users_attributes;
      companyUsers.push({company: { name: company.name, company_id: company.id, claim_groups: company.claim_groups}, authorized_for_claim_groups: [], company_id: company.id});
      setUser({...user, company_users: companyUsers, company_users_attributes: companyUsersAttributes });
    } else {
      handleToaster('Company Already Added', 'warning');
    }
  }

  const handleCompanyUserUpdate = async (event, companyUser, index) => {
    const value =
    event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value;
    const newCompanyUsers = user.company_users;
    newCompanyUsers[index][event.target.name] = value;
    setUser({...user, company_users: newCompanyUsers});
  };


  const handleAddClaimToCompanyUser = async (claimGroupId, companyUser, companyUsersIndex) => {
    const newCompanyUsers = user.company_users;
    newCompanyUsers[companyUsersIndex].authorized_for_claim_groups.push(claimGroupId);
    setUser({...user, company_users: newCompanyUsers});
  }

  const handleDeleteClaimFromCompanyUser = (index, claimGroupId) => {
    const newCompanyUsers = user.company_users;
    newCompanyUsers[index].authorized_for_claim_groups = newCompanyUsers[index].authorized_for_claim_groups.filter(authorized_id => +authorized_id !== +claimGroupId)
    setUser({...user, companyUsers: newCompanyUsers});
  }

  const handleCreateUser = async () => {
    setLoading(true);
    try {
      user.company_users_attributes.forEach(company_user_attribute => {
        const company_user = user.company_users.find(company_user => company_user.company_id === company_user_attribute.company_id);
        if (company_user) company_user_attribute.id = company_user.id;
      });
      user.company_users.forEach(company_user => {
        const company_user_attribute = user.company_users_attributes.find(company_user_attribute => company_user_attribute.company_id === company_user.company_id);
        if (!company_user_attribute) {
          user.company_users_attributes.push({
            id: company_user.id,
            _destroy: 1
          });
        }
      });
      delete user.company_users;
      await UserService.update(user);
      history.push('/admin/users');
    } catch(error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  };

  if(!companies || !user) return <Form></Form>
  return (
    <>
      <SidebarPortal headerItem={{link: 'companies', text: 'Companies'}} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: '/admin'}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: '/admin/users'}}>Users</Breadcrumb.Item>
          <Breadcrumb.Item active>{ user.id ? 'Edit' : 'New User' }</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      
      <Card>
        <Card.Body>
          <UserForm
            //handleAddClaimToCompanyUser={handleAddClaimToCompanyUser}
            //handleCompanyUserUpdate={handleCompanyUserUpdate}
            //handleCompanyUserDelete={handleCompanyUserDelete}
            //handleDeleteClaimFromCompanyUser={handleDeleteClaimFromCompanyUser}
            //handleCompanyUserCreate={handleCompanyUserCreate}
            handleChange={handleChange}
            user={user}
            companies={companies}
          />
        </Card.Body>
        <Card.Footer>
          <Row className="modal-footer">
            <Button variant="light" as={Link} to={'/admin/users'}>
              <span className="material-icons md-18">chevron_left</span> Back
            </Button>
            <Button
              onClick={() => handleCreateUser()}
              icon='save'
              iconPosition={'left'}
              loading={loading}
              variant="primary">
              Save
            </Button>
          </Row>
          </Card.Footer>
      </Card>
    </>
  )
}

export default EditUserPage
