import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Card, Row, Col, Pagination } from 'react-bootstrap';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch } from 'react-redux';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import ClaimGroupBreadcrumbLinks from '@components/shared/claim_group/edit/ClaimGroupBreadcrumbLinks';
import ClaimGroupSidebar from './ClaimGroupSidebar';
import ClaimProjectReportsList from '@components/admin/claimProjectReports/ClaimProjectReportsList';
import { useTranslation } from 'react-i18next';
import * as QueryString from 'query-string';
import claimProjectReportsListsService from '@services/lists/claim_groups/claim_project_reports_lists.service';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const initialParams = 'page=1&order_by=report_order&query=';

const ProjectOverviewPage = ({accountType, handleToaster}) => {
  const { claimGroupId } = useParams();
  const location = useLocation();
  const [claimGroup, setClaimGroup] = useState(null);
  const [reports, setReports] = useState(null)
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUnderReview, setisUnderReview] = useState(false);
  const [params, setParams] = useState(
    QueryString.parse(location.search || initialParams)
  );

  const getData = async () => {
    try {
      const claimGroup = await claimGroupService.get(claimGroupId);
      const cprs = await claimProjectReportsListsService.all(claimGroup.id, QueryString.stringify(params));
      setTotalPages(cprs.pages);
      setClaimGroup(claimGroup);
      setReports(cprs.claim_project_reports);
      setLoading(false);
      history.push({
        path: `${accountType}/claims`,
        search: QueryString.stringify(params),
      });
      if (claimGroup?.active_change_set) {
        setisUnderReview(true);
      };
    } catch (error) {
      dispatch(handleError(error));
    }
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, [history, params]);

  const handlePage = (pageNumber) => {
    window.scrollTo(0, 0);
    setParams({ ...params, page: pageNumber });
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === parseInt(params.page)}
        onClick={() => handlePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  if(!reports) return <Loading />

  return (
    <>
      { isUnderReview && 
        <Alert key='warning' variant='warning'>
          Claim info can not be edited after manager or client review has started  
        </Alert> 
      }
      <ClaimGroupSidebar claimGroup={claimGroup} highlightedMenuItem='project_overview' />
      <ClaimGroupBreadcrumbLinks claimGroup={claimGroup}/>
      <ClaimProjectReportsList 
        handleToaster={handleToaster} 
        claimGroup={claimGroup}
        getData={getData}
        setClaimGroup={setClaimGroup} 
        reports={reports} 
        params={params} 
        setParams={setParams} 
      />
      <Pagination className="justify-content-end">{items}</Pagination>
    </>
  )
}

export default ProjectOverviewPage