import React from 'react'
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { Badge } from 'react-bootstrap';
import { SHOW_CLAIM_GROUP_TASKS } from '@rd-web-markets/market/dist/constants';

const iconStyles = {
  height: '1.2rem',
  width: '1.2rem',
};

const tdCenter = { textAlign: 'center', verticalAlign: 'middle' };

const ClaimGroupListClients = ({claimGroups, loading, accountType}) => {
  const renderEmptyHeaders = () => {
    return (
      <tr>
        <th style={{ width: '100%' }}>Claim Group</th>
        <th className="text-center"></th>
        <th className="text-center"></th>
        <th className="text-center"></th>
        <th className="text-center"></th>
        <th className="text-center"></th>
        <th className="text-center"></th>
        <th className="text-center"></th>
        <th className="text-center"></th>
      </tr>
    )
  }

  const renderHeaders = () => {
    return (
      <tr>
        <th>Claim Group</th>
        <th className="text-center">Registration</th>
        <th className="text-center">Cost Collection</th>
        <th className="text-center">Cost Review </th>
        <th className="text-center">Technical Assessment</th>
        <th className="text-center">Review & Submission</th>
        <th className="text-center">Claim Management</th>
        <th className="text-center">Claim Report Status</th>
        <th className="text-center"></th>
      </tr>
    )
  };

  const renderTaskIconStatus = (status, categoryTitle) => {
    switch(status) {
      case 'not_started':
        return (
          <Badge pill variant="danger" data-testid={`icon-${categoryTitle}-badge-not_started`}>
            <span style={iconStyles} className="material-icons md-18 text-white">close</span>
          </Badge>
        )
      case 'in_progress':
        return (
          <Badge pill variant="primary" style={{backgroundColor: '#00AEEF'}}  data-testid={`icon-${categoryTitle}-badge-in_progress`}>
            <span className="material-icons text-white" style={iconStyles}>format_align_center</span>
          </Badge>
        )
      case 'completed':
        return (
          <Badge pill variant="success" data-testid={`icon-${categoryTitle}-badge-success`}>
            <span className="material-icons text-white" style={iconStyles}>done</span>
          </Badge>
        )
      default:
        <></>
    }
  }

  const renderViewReportButton = (claimGroup) => {
    if(claimGroup.report_completion && claimGroup.report_template){
      return (
        <>
          <Button
            variant='primary'
            size='sm'
            as={Link}
            to={`/${accountType}/report_template/${claimGroup.report_template.id}`}>
            View Report
          </Button> <br />
          Completed
        </>
      )
    } else if(claimGroup.active_change_set && claimGroup.active_change_set.review_type === 'client_review' && claimGroup.report_template) {
        if(claimGroup.active_change_set.finalized_at) {
          return (<>
            <Button
              variant='success'
              size='sm'
              as={Link}
              to={`/${accountType}/report_template/${claimGroup.report_template.id}`}>
              View Report
            </Button> <br />
              Completed
            </> )
        } else {
          return (<>
          <Button
            variant='success'
            size='sm'
            as={Link}
            to={`/${accountType}/report_template/${claimGroup.report_template.id}`}>
            View Report
          </Button> <br />
          Draft
          </> )
        }
    } else {
      return (
        <>
          <Button disabled='true' size='sm'>View Report</Button> <br />
          Not Completed
        </>
      )
    }
  }

  const renderTasks = (claimGroup) => {
    return (
      claimGroup.tasks.map((t) => {
        return (
          <td style={tdCenter} key={t.category_title}>
            {renderTaskIconStatus(t.status, t.category_title)}
          </td>
        );
      })
    )
  };

  const renderEmptyTable = () => {
    return (
      <>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </>
    )
  }

  return (
    <Table hover>
      <thead>
          { 
            SHOW_CLAIM_GROUP_TASKS ?
              renderHeaders()
              :
              renderEmptyHeaders()
          }
      </thead>
      <tbody>
        {claimGroups.map((claimGroup) => {
            return(
              <tr>
                <td>
                  <>{claimGroup.name}</>
                </td>
                {
                  claimGroup.tasks.length && (
                    SHOW_CLAIM_GROUP_TASKS ?
                      renderTasks(claimGroup)
                      :
                      renderEmptyTable()
                  )
                }
                <td style={tdCenter}>
                  {renderViewReportButton(claimGroup)}
                </td>
                <td style={tdCenter}>
                  <Button variant='primary' size='sm' as={Link} to={`/${accountType}/companies/${claimGroup.company_id}/${claimGroup.id}`}>
                    View Cost & Document Upload
                  </Button>
                </td>
              </tr>
            )
        })}
      </tbody>
    </Table>
  )
}

export default ClaimGroupListClients
